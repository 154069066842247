import axios from 'axios'
import queryString from 'query-string'
import apiAction from '../tools/apiAction'
import Bugsnag from '../../utils/Bugsnag'

let BASE_URL = '/_api'

/* for server-side render */
if (!process.browser) {
  BASE_URL = process.env.API_BASE_URL || 'https://dev.pot-luck.jp/_api'
}

export const ACTIONS = {
  LOGIN: 'LOGIN',
  LOGIN_SNS: 'LOGIN_SNS',
  LOGIN_LINK: 'LOGIN_LINK',
  MEALS_BY_CATEGORY: 'MEALS_BY_CATEGORY',
  INVITE_INFORMATION: 'INVITE_INFORMATION',
  REQUEST_PASSWORD_RESET_LINK: 'REQUEST_PASSWORD_RESET_LINK',
  USE_PASSWORD_RESET_LINK: 'USE_PASSWORD_RESET_LINK',
  LOGOUT: 'LOGOUT',
  CREATE_PHONE_VERIFICATION_CODE: 'CREATE_PHONE_VERIFICATION_CODE',
  CHECK_PHONE_VERIFICATION_CODE: 'CHECK_PHONE_VERIFICATION_CODE',
  CREATE_EMAIL_VERIFICATION_CODE: 'CREATE_EMAIL_VERIFICATION_CODE',
  CHECK_EMAIL_VERIFICATION_CODE: 'CHECK_EMAIL_VERIFICATION_CODE',
  CREATE_GUEST_USER: 'CREATE_GUEST_USER',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_SNS: 'CREATE_USER_SNS',
  CONTINUE_WITH_APPLE: 'CONTINUE_WITH_APPLE',
  PLANS: 'PLANS',
  PROMO: 'PROMO',
  OFFER: 'OFFER',
  HOME: 'HOME',
  FAQ: 'FAQ',
  SAVED_LOCATIONS: 'SAVED_LOCATIONS',
  CREATE_SAVED_LOCATION: 'CREATE_SAVED_LOCATION',
  FLYERS: 'FLYERS',
  DIALOGS: 'DIALOGS',
  MARK_DIALOG_AS_SEEN: 'MARK_DIALOG_AS_SEEN',
  GIFT_CODE: 'GIFT_CODE',
  REFERRAL_OFFER: 'REFERRAL_OFFER',
  REFERRALS: 'REFERRALS',
  REDEEM_GIFT_CODE: 'REDEEM_GIFT_CODE',
  RESET_PASSWORD: 'RESET_PASSWORD',
  VERIFY_USER: 'VERIFY_USER',
  CHECK_REFERRAL_CODE: 'CHECK_REFERRAL_CODE',
  RESEND_VERIFICATION_EMAIL: 'RESEND_VERIFICATION_EMAIL',
  UNSUBSCRIBE_FROM_DAILY_REMINDERS: 'UNSUBSCRIBE_FROM_DAILY_REMINDERS',
  GRANTED_PROMOS: 'GRANTED_PROMOS',
  SHOPS: 'SHOPS',
  SHOP_BY_ID: 'SHOP_BY_ID',
  SHOP_BY_DESCRIPTOR: 'SHOP_BY_DESCRIPTOR',
  SHOPS_APP_BY_DESCRIPTOR: 'SHOPS_APP_BY_DESCRIPTOR',
  MEALS_FOR_SHOP: 'MEALS_FOR_SHOP',
  MEAL_BY_ID: 'MEAL_BY_ID',
  SHOP_BY_MEAL_ID: 'SHOP_BY_MEAL_ID',
  NEIGHBORHOODS: 'NEIGHBORHOODS',
  SEARCH_AUTOCOMPLETE: 'SEARCH_AUTOCOMPLETE',
  SUGGESTED_SEARCHES: 'SUGGESTED_SEARCHES',
  SEARCH_NEIGHBORHOOD: 'SEARCH_NEIGHBORHOOD',
  SEARCH_MEALS: 'SEARCH_MEALS',
  MEALS_BY_TAG: 'MEALS_BY_TAG',
  SEARCH_SHOPS: 'SEARCH_SHOPS',
  SHOPS_BY_TAG: 'SHOPS_BY_TAG',
  SEARCH_HISTORY: 'SEARCH_HISTORY',
  CREATE_SEARCH_HISTORY: 'CREATE_SEARCH_HISTORY',
  DELETE_SEARCH_HISTORY: 'DELETE_SEARCH_HISTORY',
  FAVORITES: 'FAVORITES',
  ADD_FAVORITE: 'ADD_FAVORITE',
  REMOVE_FAVORITE: 'REMOVE_FAVORITE',
  MEAL_VIEWS: 'MEAL_VIEWS',
  CREATE_MEAL_VIEW: 'CREATE_MEAL_VIEW',
  RESERVATIONS: 'RESERVATIONS',
  ORDER_INFORMATION: 'ORDER_INFORMATION',
  CREATE_RESERVATIONS: 'CREATE_RESERVATIONS',
  MARK_POTLUCK_NOW_RESERVATION_COMPLETED: 'MARK_POTLUCK_NOW_RESERVATION_COMPLETED',
  REDEEM_REWARD_FOR_RESERVATION: 'REDEEM_REWARD_FOR_RESERVATION',
  UPCOMING: 'UPCOMING',
  REVIEWS: 'REVIEWS',
  CHECK_DELIVERY_ADDRESS: 'CHECK_DELIVERY_ADDRESS',
  CREATE_REVIEW: 'CREATE_REVIEW',
  MARK_REVIEWS_MODAL_SEEN: 'MARK_REVIEWS_MODAL_SEEN',
  REVIEW_SHARE_IMAGE: 'REVIEW_SHARE_IMAGE',
  CREATE_REVIEW_SHARE_LOG: 'CREATE_REVIEW_SHARE_LOG',
  CANCELATION_SURVEY: 'CANCELATION_SURVEY',
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
  PROFILE: 'PROFILE',
  SET_PROFILE: 'SET_PROFILE',
  SET_USER_PREFERENCE: 'SET_USER_PREFERENCE',
  WALLET: 'WALLET',
  PROMO_REDEMPTIONS: 'PROMO_REDEMPTIONS',
  CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
  PREVIEW_PURCHASE: 'PREVIEW_PURCHASE',
  CREATE_PURCHASE: 'CREATE_PURCHASE',
  PURCHASE_ADDON: 'PURCHASE_ADDON',
  REMOVE_ADDON: 'REMOVE_ADDON',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  PAUSE_SUBSCRIPTION: 'PAUSE_SUBSCRIPTION',
  RESUME_SUBSCRIPTION: 'RESUME_SUBSCRIPTION',
  CARDS: 'CARDS',
  ADD_CARD: 'ADD_CARD',
  SET_DEFAULT_CARD: 'SET_DEFAULT_CARD',
  DELETE_CARD: 'DELETE_CARD',
  CHARGES: 'CHARGES',
  ORGANIZATION_MEMBERS: 'ORGANIZATION_MEMBERS',
  ORGANIZATION_INVITES: 'ORGANIZATION_INVITES',
  INVITE_MEMBER_TO_ORGANIZATION: 'INVITE_MEMBER_TO_ORGANIZATION',
  REMOVE_MEMBER_FROM_ORGANIZATION: 'REMOVE_MEMBER_FROM_ORGANIZATION',
  ACCEPT_ORGANIZATION_INVITE: 'ACCEPT_ORGA@NIZATION_INVITE',
  DECLINE_ORGANIZATION_INVITE: 'DECLINE_ORGANIZATION_INVITE',
  LEAVE_ORGANIZATION: 'LEAVE_ORGANIZATION',
  ORGANIZATION_UPCOMING: 'ORGANIZATION_UPCOMING',
  REWARDS: 'REWARDS',
  CREATE_PUSH_TOKEN: 'CREATE_PUSH_TOKEN',
  UPLOAD_PHOTO: 'UPLOAD_PHOTO',
  FRIENDS: 'FRIENDS',
  FRIEND_REQUESTS: 'FRIEND_REQUESTS',
  CREATE_FRIEND_REQUEST: 'CREATE_FRIEND_REQUEST',
  ACCEPT_FRIEND_REQUEST: 'ACCEPT_FRIEND_REQUEST',
  DENY_FRIEND_REQUEST: 'DENY_FRIEND_REQUEST',
  REMOVE_FRIEND: 'REMOVE_FRIEND',
  WORKPLACE: 'WORKPLACE',
  ADD_WORKPLACE: 'ADD_WORKPLACE',
  CREATE_SATISFACTION_SURVEY: 'CREATE_SATISFACTION_SURVEY',
  ORDER_PICKUP_OPTIONS: 'ORDER_PICKUP_OPTIONS',
  ORDER_PREVIEW: 'ORDER_PREVIEW',
  CREATE_PREVIEW: 'CREATE_PREVIEW',
  CREATE_ORDER: 'CREATE_ORDER',
  CANCEL_ORDER: 'CANCEL_ORDER',
  MARK_POTLUCK_NOW_ORDER_COMPLETED: 'MARK_POTLUCK_NOW_ORDER_COMPLETED',
  PAST_ORDERS: 'PAST_ORDERS',
  UPCOMING_ORDERS: 'UPCOMING_ORDERS',
  FRIENDS_ORDERS: 'FRIENDS_ORDERS',
  DELIVERY_LOCATIONS: 'DELIVERY_LOCATIONS',
  SAVE_DELIVERY_LOCATION: 'SAVE_DELIVERY_LOCATION',
  NEARBY_LOCATIONS: 'NEARBY_LOCATIONS',
  AUTOCOMPLETE_LOCATIONS: 'AUTOCOMPLETE_LOCATIONS',
  COORDINATES_FOR_LOCATION: 'COORDINATES_FOR_LOCATION',
  FREE_DELIVERY_OPTIONS: 'FREE_DELIVERY_OPTIONS',
}

/*
 * Helper functions to create api functions
 */
const makeGetRequest = (action, baseUrl, options={}) => apiAction(action, (dispatch, commonProps, axiosOptions={}) => {
  return axios.get(`${baseUrl}?${queryString.stringify(commonProps)}`, { withCredentials: true, ...axiosOptions })
}, options)

const makePostRequest = (action, baseUrl, body={}, options={}) => apiAction(action, (dispatch, commonProps, axiosOptions={}) => {
  return axios.post(baseUrl, {
    ...commonProps,
    ...body,
  }, { withCredentials: true, ...axiosOptions })
}, options)

const makeDeleteRequest = (action, baseUrl, body={}, options={}) => apiAction(action, (dispatch, commonProps, axiosOptions={}) => {
  return axios.delete(baseUrl, {
    withCredentials: true,
    data: {
      ...commonProps,
      ...body,
    },
    ...axiosOptions,
  })
}, options)

export const getHomeKey = data => `${data.orderType}-${data?.origin?.latitude}-${data?.origin?.longitude}`
export const getSearchShopsCacheKey = search => `${search.tag}-${search?.origin?.lat}-${search?.origin?.lon}`
export const getSearchMealsByTagCacheKey = search => `${search.tag}-${search?.origin?.lat}-${search?.origin?.lon}-${search?.page}-${search.orderType}`
export const getSearchShopsByTagCacheKey = search => `${search.tag}-${search?.origin?.lat}-${search?.origin?.lon}-${search?.page}-${search.orderType}`

/*
 * API Endpoints
 */
export const getOffer = offerId => makeGetRequest(ACTIONS.OFFER, `${BASE_URL}/v2/offer/${offerId}`, { key: offerId })
export const getFaq = () => makeGetRequest(ACTIONS.FAQ, `${BASE_URL}/v1/faq`)
export const getSavedLocations = () => makeGetRequest(ACTIONS.SAVED_LOCATIONS, `${BASE_URL}/v1/saved-locations`)
export const getSavedLocationsIfNeeded = () => makeGetRequest(ACTIONS.SAVED_LOCATIONS, `${BASE_URL}/v1/saved-locations`, { useCache: true })

export const getWallet = () => makeGetRequest(ACTIONS.WALLET, `${BASE_URL}/v1/wallet`)
export const getWalletIfNeeded = () => makeGetRequest(ACTIONS.WALLET, `${BASE_URL}/v1/wallet`, { useCache: true })
export const getRewards = () => makeGetRequest(ACTIONS.REWARDS, `${BASE_URL}/v1/rewards`)
export const getReservations = () => makeGetRequest(ACTIONS.RESERVATIONS, `${BASE_URL}/v1/reservations`)
export const getUpcoming = () => makeGetRequest(ACTIONS.UPCOMING, `${BASE_URL}/v1/reservations/upcoming`)
export const getMealsForShop = shopId => makeGetRequest(ACTIONS.MEALS_FOR_SHOP, `${BASE_URL}/v1/shops/${shopId}/meals`, { key: shopId })
export const getMealById = mealId => makeGetRequest(ACTIONS.MEAL_BY_ID, `${BASE_URL}/v1/meals/${mealId}`, { key: mealId })
export const getMealByIdIfNeeded = mealId => makeGetRequest(ACTIONS.MEAL_BY_ID, `${BASE_URL}/v1/meals/${mealId}`, { useCache: true, key: mealId })
export const getShopByMealId = mealId => makeGetRequest(ACTIONS.SHOP_BY_MEAL_ID, `${BASE_URL}/v1/shops/meal/${mealId}`, { key: mealId })
export const getReviews = () => makeGetRequest(ACTIONS.REVIEWS, `${BASE_URL}/v1/reviews`)
export const getSearchHistory = () => makeGetRequest(ACTIONS.SEARCH_HISTORY, `${BASE_URL}/v3/search/history`)
export const getFavorites = () => makeGetRequest(ACTIONS.FAVORITES, `${BASE_URL}/v1/meals/favorites`)
export const getFavoritesIfNeeded = () => makeGetRequest(ACTIONS.FAVORITES, `${BASE_URL}/v1/meals/favorites`, { useCache: true })
export const getInviteInformation = () => makeGetRequest(ACTIONS.INVITE_INFORMATION, `${BASE_URL}/v2/profile/invite-information`)
export const getCharges = () => makeGetRequest(ACTIONS.CHARGES, `${BASE_URL}/v1/profile/charges`)
export const getNeighborhoods = () => makeGetRequest(ACTIONS.NEIGHBORHOODS, `${BASE_URL}/v1/neighborhoods`)
export const getNeighborhoodsIfNeeded = () => makeGetRequest(ACTIONS.NEIGHBORHOODS, `${BASE_URL}/v1/neighborhoods`, { useCache: true })
export const getCards = () => makeGetRequest(ACTIONS.CARDS, `${BASE_URL}/v1/profile/cards`)
export const getCardsIfNeeded = () => makeGetRequest(ACTIONS.CARDS, `${BASE_URL}/v1/profile/cards`, { useCache: true })
export const getCancelationSurvey = () => makeGetRequest(ACTIONS.CANCELATION_SURVEY, `${BASE_URL}/v1/profile/subscription/cancelation-survey`)
export const getFriends = () => makeGetRequest(ACTIONS.FRIENDS, `${BASE_URL}/v1/friends`)
export const getFriendRequests = () => makeGetRequest(ACTIONS.FRIEND_REQUESTS, `${BASE_URL}/v1/friends/request`)
export const getShops = () => makeGetRequest(ACTIONS.SHOPS, `${BASE_URL}/v1/shops`)
export const getShopsIfNeeded = () => makeGetRequest(ACTIONS.SHOPS, `${BASE_URL}/v1/shops`, { useCache: true })
export const getShopById = shopId => makeGetRequest(ACTIONS.SHOP_BY_ID, `${BASE_URL}/v1/shops/${shopId}`, { key: shopId })
export const getShopByIdIfNeeded = shopId => makeGetRequest(ACTIONS.SHOP_BY_ID, `${BASE_URL}/v1/shops/${shopId}`, { useCache: true, key: shopId })
export const getShopByDescriptor = descriptor => makeGetRequest(ACTIONS.SHOP_BY_DESCRIPTOR, `${BASE_URL}/v1/shops/by-descriptor/${descriptor}?shopsApp=true`, { key: descriptor })
export const getShopsAppByDescriptor = descriptor => makeGetRequest(ACTIONS.SHOPS_APP_BY_DESCRIPTOR, `${BASE_URL}/v1/shops/app/${descriptor}`, { key: descriptor })
export const getShopsAppByDescriptorIfNeeded = descriptor => makeGetRequest(ACTIONS.SHOPS_APP_BY_DESCRIPTOR, `${BASE_URL}/v1/shops/app/${descriptor}`, { useCache: true, key: descriptor })
export const getProfile = () => makeGetRequest(ACTIONS.PROFILE, `${BASE_URL}/v2/profile`)
export const getProfileIfNeeded = () => makeGetRequest(ACTIONS.PROFILE, `${BASE_URL}/v1/profile`, { useCache: true })
export const getWorkplace = () => makeGetRequest(ACTIONS.WORKPLACE, `${BASE_URL}/v1/profile/workplace`)
export const getPastOrders = page => makeGetRequest(ACTIONS.PAST_ORDERS, `${BASE_URL}/v1/orders/past/${page}`, { key: page })
export const getUpcomingOrders = () => makeGetRequest(ACTIONS.UPCOMING_ORDERS, `${BASE_URL}/v1/orders/upcoming`)
export const getFriendsOrders = () => makeGetRequest(ACTIONS.FRIENDS_ORDERS, `${BASE_URL}/v1/orders/friends`)
export const getDeliveryLocations = () => makeGetRequest(ACTIONS.DELIVERY_LOCATIONS, `${BASE_URL}/v1/delivery-locations`)
export const getDeliveryLocationsIfNeeded = () => makeGetRequest(ACTIONS.DELIVERY_LOCATIONS, `${BASE_URL}/v1/delivery-locations`, { useCache: true })
export const getCoordinatesForLocation = googlePlaceId => makeGetRequest(ACTIONS.COORDINATES_FOR_LOCATION, `${BASE_URL}/v1/geo/coordinates/${googlePlaceId}`)

export const login = ({ phone, email, password }) => makePostRequest(ACTIONS.LOGIN, `${BASE_URL}/v1/auth/login`, { phone, email, password })
export const loginSns = ({ token, provider }) => makePostRequest(ACTIONS.LOGIN_SNS, `${BASE_URL}/v1/auth/login/sns`, { token, provider })
export const useLoginLink = code => makePostRequest(ACTIONS.LOGIN_LINK, `${BASE_URL}/v1/use-login-link`, { code })
export const createUser = user => makePostRequest(ACTIONS.CREATE_USER, `${BASE_URL}/v1/users/create`, user)
export const editProfile = profile => makePostRequest(ACTIONS.PROFILE, `${BASE_URL}/v1/profile`, profile)
export const getDialogs = body => makePostRequest(ACTIONS.DIALOGS, `${BASE_URL}/v5/dialogs`, body)
export const createPhoneVerificationCode = phone => makePostRequest(ACTIONS.CREATE_PHONE_VERIFICATION_CODE, `${BASE_URL}/v1/phone-verification-code`, { phone })
export const checkPhoneVerificationCode = ({ phone, verificationCode }) => makePostRequest(ACTIONS.CHECK_PHONE_VERIFICATION_CODE, `${BASE_URL}/v1/check-phone-verification-code`, { phone, verificationCode })
export const createEmailVerificationCode = email => makePostRequest(ACTIONS.CREATE_EMAIL_VERIFICATION_CODE, `${BASE_URL}/v1/email-verification-code`, { email })
export const checkEmailVerificationCode = ({ email, verificationCode }) => makePostRequest(ACTIONS.CHECK_EMAIL_VERIFICATION_CODE, `${BASE_URL}/v1/check-email-verification-code`, { email, verificationCode })
export const checkReferralCode = referralCode => makePostRequest(ACTIONS.CHECK_REFERRAL_CODE, `${BASE_URL}/v1/users/referral-code/check`, { referralCode })
export const getHome = data => makePostRequest(ACTIONS.HOME, `${BASE_URL}/v6/home`, data, { key: getHomeKey(data) })
export const getHomeIfNeeded = data => makePostRequest(ACTIONS.HOME, `${BASE_URL}/v6/home`, data, { useCache: true, key: getHomeKey(data) })
export const createSavedLocation = location => makePostRequest(ACTIONS.CREATE_SAVED_LOCATION, `${BASE_URL}/v1/saved-locations`, location)
export const setUserPreference = ({ name, value }) => makePostRequest(ACTIONS.SET_USER_PREFERENCE, `${BASE_URL}/v1/profile/preference`, { name, value })
export const createMealView = mealId => makePostRequest(ACTIONS.CREATE_MEAL_VIEW, `${BASE_URL}/v1/meals/views`, { mealId })
export const checkDeliveryAddress = deliveryAddress => makePostRequest(ACTIONS.CHECK_DELIVERY_ADDRESS, `${BASE_URL}/v2/check-delivery-address`, deliveryAddress)
export const searchMeals = search => makePostRequest(ACTIONS.SEARCH_MEALS, `${BASE_URL}/v2/check-delivery-address`, search)
export const searchMealsByTag = search => makePostRequest(ACTIONS.MEALS_BY_TAG, `${BASE_URL}/v3/search/meals`, search, { key: getSearchMealsByTagCacheKey(search) })
export const searchMealsByTagIfNeeded = search => makePostRequest(ACTIONS.MEALS_BY_TAG, `${BASE_URL}/v3/search/meals`, search, { key: getSearchMealsByTagCacheKey(search), useCache: true })
export const searchShops = search => makePostRequest(ACTIONS.SEARCH_SHOPS, `${BASE_URL}/v3/search/shops`, search, { useCache: true, key: getSearchShopsCacheKey(search) })
export const searchShopsIfNeeded = search => makePostRequest(ACTIONS.SEARCH_SHOPS, `${BASE_URL}/v3/search/shops`, search, { useCache: true, key: getSearchShopsCacheKey(search) })
export const searchShopsByTag = search => makePostRequest(ACTIONS.SHOPS_BY_TAG, `${BASE_URL}/v3/search/shops`, search, { key: getSearchShopsByTagCacheKey(search) })
export const createSearchHistory = search => makePostRequest(ACTIONS.CREATE_SEARCH_HISTORY, `${BASE_URL}/v3/search/history`, search)
export const addFavorite = mealId => makePostRequest(ACTIONS.ADD_FAVORITE, `${BASE_URL}/v1/meals/favorites/add`, { mealId })
export const removeFavorite = mealId => makePostRequest(ACTIONS.REMOVE_FAVORITE, `${BASE_URL}/v1/meals/favorites/remove`, { mealId })
export const autocomplete = ({ text, origin }) => makePostRequest(ACTIONS.SEARCH_AUTOCOMPLETE, `${BASE_URL}/v3/search/autocomplete`, { text, origin }, { key: text })
export const getSuggestedSearches = ({ origin }) => makePostRequest(ACTIONS.SUGGESTED_SEARCHES, `${BASE_URL}/v3/search/suggested-searches`, { origin })
export const searchNeighborhood = coordinate => makePostRequest(ACTIONS.SEARCH_NEIGHBORHOOD, `${BASE_URL}/v2/search/neighborhood`, { coordinate })
export const getMealsByCategory = query => makePostRequest(ACTIONS.MEALS_BY_CATEGORY, `${BASE_URL}/v1/meals/categories`, { query })
export const setProfile = profile => makePostRequest(ACTIONS.SET_PROFILE, `${BASE_URL}/v1/profile`, profile)
export const resetPassword = password => makePostRequest(ACTIONS.RESET_PASSWORD, `${BASE_URL}/v1/users/reset-password`, { password })
export const createReview = review => makePostRequest(ACTIONS.CREATE_REVIEW, `${BASE_URL}/v1/reviews`, review)
export const makeReviewShareImage = data => makePostRequest(ACTIONS.REVIEW_SHARE_IMAGE, `${BASE_URL}/v1/review-share-image`, data)
export const createReviewShareLog = data => makePostRequest(ACTIONS.CREATE_REVIEW_SHARE_LOG, `${BASE_URL}/v1/review-share-log`, data)
export const createPushToken = ({ token, os }) => makePostRequest(ACTIONS.CREATE_PUSH_TOKEN, `${BASE_URL}/v1/push-tokens`, { token, os })
export const addCard = token => makePostRequest(ACTIONS.ADD_CARD, `${BASE_URL}/v1/profile/cards`, { token })
export const setDefaultCard = cardId => makePostRequest(ACTIONS.SET_DEFAULT_CARD, `${BASE_URL}/v1/profile/cards/default`, { cardId })
export const previewPurchase = ({ productCode, addonCode, ccFingerprint }) => makePostRequest(ACTIONS.PREVIEW_PURCHASE, `${BASE_URL}/v1/profile/purchase-preview`, { productCode, addonCode, ccFingerprint })
export const createPurchase = ({ productCode, addonCode, ccFingerprint }) => makePostRequest(ACTIONS.CREATE_PURCHASE, `${BASE_URL}/v1/profile/purchase`, { productCode, addonCode, ccFingerprint })
export const createSubscription = ({ planId, promo, ccFingerprint, discountCode, overrideImmediate=false }) => makePostRequest(ACTIONS.CREATE_SUBSCRIPTION, `${BASE_URL}/v1/profile/subscription`, { planId, promo, ccFingerprint, discountCode, overrideImmediate })
export const purchaseAddon = ({ planId }) => makePostRequest(ACTIONS.PURCHASE_ADDON, `${BASE_URL}/v1/profile/addon`, { planId })
export const pauseSubscription = () => makePostRequest(ACTIONS.PAUSE_SUBSCRIPTION, `${BASE_URL}/v1/profile/subscription/pause`)
export const resumeSubscription = () => makePostRequest(ACTIONS.RESUME_SUBSCRIPTION, `${BASE_URL}/v1/profile/subscription/resume`)
export const createFriendRequest = referralCode => makePostRequest(ACTIONS.CREATE_FRIEND_REQUEST, `${BASE_URL}/v1/friends/request`, { referralCode })
export const acceptFriendRequest = friendRequestId => makePostRequest(ACTIONS.ACCEPT_FRIEND_REQUEST, `${BASE_URL}/v1/friends/accept-request`, { friendRequestId })
export const denyFriendRequest = friendRequestId => makePostRequest(ACTIONS.DENY_FRIEND_REQUEST, `${BASE_URL}/v1/friends/deny-request`, { friendRequestId })
export const removeFriend = friendId => makePostRequest(ACTIONS.REMOVE_FRIEND, `${BASE_URL}/v1/friends/remove`, { friendId })
export const addWorkplace = workplace => makePostRequest(ACTIONS.ADD_WORKPLACE, `${BASE_URL}/v1/profile/workplace`, workplace)
export const createSatisfactionSurvey = survey => makePostRequest(ACTIONS.CREATE_SATISFACTION_SURVEY, `${BASE_URL}/v1/satisfaction-survey`, survey)
export const getOrderPickupOptions = order => makePostRequest(ACTIONS.ORDER_PICKUP_OPTIONS, `${BASE_URL}/v1/orders/pickup-options`, order)
export const getOrderPreview = order => makePostRequest(ACTIONS.ORDER_PREVIEW, `${BASE_URL}/v1/orders/preview`, order)
export const createOrder = order => makePostRequest(ACTIONS.CREATE_PREVIEW, `${BASE_URL}/v1/orders`, order)
export const saveDeliveryLocation = deliveryLocation => makePostRequest(ACTIONS.SAVE_DELIVERY_LOCATION, `${BASE_URL}/v1/delivery-locations`, deliveryLocation)
export const markPotluckNowOrderCompleted = orderId => makePostRequest(ACTIONS.MARK_POTLUCK_NOW_ORDER_COMPLETED, `${BASE_URL}/v1/orders/mark-complete`, { orderId })
export const getNearbyLocations = origin => makePostRequest(ACTIONS.NEARBY_LOCATIONS, `${BASE_URL}/v1/geo/nearby-locations`, origin)
export const autocompleteLocations = query => makePostRequest(ACTIONS.AUTOCOMPLETE_LOCATIONS, `${BASE_URL}/v1/geo/autocomplete-locations`, query)
export const getFreeDeliveryOptions = location => makePostRequest(ACTIONS.FREE_DELIVERY_OPTIONS, `${BASE_URL}/v3/search/free-delivery`, location)
export const logout = () => makePostRequest(ACTIONS.LOGOUT, `${BASE_URL}/v1/auth/logout`, {})

export const deleteSearchHistory = searchId => makeDeleteRequest(ACTIONS.DELETE_SEARCH_HISTORY, `${BASE_URL}/v3/search/history`, { searchId })
export const deleteCard = cardId => makeDeleteRequest(ACTIONS.DELETE_CARD, `${BASE_URL}/v1/profile/cards/${cardId}`)
export const removeAddon = ({ planId }) => makeDeleteRequest(ACTIONS.PURCHASE_ADDON, `${BASE_URL}/v1/profile/addon`, { planId })
export const cancelSubscription = data => makeDeleteRequest(ACTIONS.CANCEL_SUBSCRIPTION, `${BASE_URL}/v1/profile/subscription`, data)
export const cancelOrder = orderId => makeDeleteRequest(ACTIONS.CANCEL_ORDER, `${BASE_URL}/v1/orders/${orderId}`)
